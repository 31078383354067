import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, AlertDescription } from '../components/ui/alert';
const PDFRedactionComponent = () => {
  const [file, setFile] = useState(null);
  const [redactText, setRedactText] = useState('Remove personal information such as emails and phone numbers');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(interval);
            return prevProgress;
          }
          return prevProgress + Math.random() * 2; // Slower progress
        });
      }, 1000); // Increased interval for slower progress
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setError('');
    } else {
      setFile(null);
      setError('Please select a valid PDF file.');
    }
  };

  const handleRedactTextChange = (e) => {
    setRedactText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please upload a PDF file.');
      return;
    }

    setIsLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('redact_text', redactText);
    formData.append('langs', 'English');

    try {
      const response = await axios.post('https://surya.beaglenetwork.com/redact_pdf/', formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProgress(100);

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = 'redacted_document.pdf';
      fileLink.click();
      URL.revokeObjectURL(fileURL);
    } catch (err) {
      setError('An error occurred while redacting the PDF. Please try again.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-2 text-center text-blue-600">AI-Powered PDF Redaction</h1>
      <Alert className="mb-8 bg-gradient-to-r from-purple-500 to-pink-500 border-none text-white shadow-lg p-4">
        <AlertDescription className="text-center py-3 text-lg">
          <span className="font-semibold text-xl">Upgrade your experience!</span><br />
          For high quality PDF, API access, or watermark removal, email{' '}
          <a href="mailto:hello@ai-redact.com" className="font-bold underline hover:text-yellow-300 transition-colors">
            hello@ai-redact.com
          </a>
        </AlertDescription>
      </Alert>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="pdf-upload">
            Upload PDF
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                >
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} accept=".pdf"/>
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">PDF up to 10MB</p>
            </div>
          </div>
          {file && (
            <p className="mt-2 text-sm text-gray-600">
              Selected file: {file.name}
            </p>
          )}
        </div>

        <div>
          <label htmlFor="redact-text" className="block text-sm font-medium text-gray-700 mb-2">
            What information would you like to redact?
          </label>
          <p className="text-xs text-gray-500 mb-2">
            Specify the type of information you want to remove or protect in the PDF.
          </p>
          <textarea
            id="redact-text"
            name="redact-text"
            rows={3}
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
            placeholder="E.g., remove any PII data, redact names and addresses"
            value={redactText}
            onChange={handleRedactTextChange}
          ></textarea>
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            {isLoading ? 'Redacting...' : 'Redact PDF'}
          </button>
        </div>

        {isLoading && (
          <div className="mt-4">
            <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div 
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <p className="text-center text-sm mt-2 text-gray-600">
              {progress < 100 ? 'Redacting your PDF...' : 'Redaction complete! Downloading...'}
            </p>
          </div>
        )}
      </form>

      {error && (
        <div className="mt-4 text-red-600 text-center">
          {error}
        </div>
      )}
    </div>
  );
};

export default PDFRedactionComponent;