import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHead = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "AI-Powered PDF Redaction Tool",
    "applicationCategory": "BusinessApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "operatingSystem": "Web-based",
    "description": "Secure your documents with our AI-powered PDF redaction tool. Automatically identify and remove sensitive information from your PDFs.",
  };

  return (
    <Helmet>
      <title>AI-Powered PDF Redaction Tool | Secure Document Redaction</title>
      <meta
        name="description"
        content="Protect sensitive information in your PDFs with our AI-powered redaction tool. Automatically identify and remove and redact confidential data in PDFs for secure document sharing."
      />
      <meta
        name="keywords"
        content="PDF redaction, document security, AI redaction, confidential information protection, GDPR compliance, HIPAA compliance"
      />
      <link rel="canonical" href="https://ai-redact.com/" />
      <meta property="og:title" content="AI-Powered PDF Redaction Tool" />
      <meta
        property="og:description"
        content="Secure your documents with AI-powered PDF redaction. Protect sensitive information easily and efficiently."
      />
      <meta property="og:url" content="https://ai-redact.com/" />
      <meta property="og:type" content="website" />
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-9TE7NGXFZP"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-9TE7NGXFZP');
        `}
      </script>
    </Helmet>
  );
};

export default SEOHead;
