import React from 'react';

const SEOComponent = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 text-gray-800">
      <h2 className="text-3xl font-bold mb-6">Secure Your Documents with AI-Powered PDF Redaction</h2>
      
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">What is PDF Redaction?</h3>
        <p className="mb-4">
          PDF redaction is the process of removing sensitive or confidential information from a document before it is published or shared. Our intelligent PDF redaction tool uses advanced AI technology to automatically identify and obscure potentially sensitive data, ensuring your documents are safe for distribution.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Why Choose Our AI PDF Redaction Tool?</h3>
        <ul className="list-disc pl-6 space-y-2">
          <li>Advanced AI technology for accurate identification of sensitive information</li>
          <li>User-friendly interface for easy document uploading and redaction</li>
          <li>Customizable redaction options to meet your specific needs</li>
          <li>Secure processing to maintain the confidentiality of your documents</li>
          <li>Quick and efficient redaction process, saving you time and effort</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Common Use Cases for PDF Redaction</h3>
        <p className="mb-4">
          Our PDF redaction tool is perfect for a wide range of industries and use cases, including:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Legal firms handling sensitive client information</li>
          <li>Healthcare providers protecting patient data (HIPAA compliance)</li>
          <li>Financial institutions securing customer financial records</li>
          <li>Government agencies preparing documents for public release</li>
          <li>Businesses protecting proprietary information in reports</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How Our PDF Redaction Process Works</h3>
        <ol className="list-decimal pl-6 space-y-2">
          <li>Upload your PDF document to our secure platform</li>
          <li>Specify the types of information you want to redact or let our AI detect sensitive data</li>
          <li>Review the AI-suggested redactions and make any necessary adjustments</li>
          <li>Confirm and process the redactions</li>
          <li>Download your securely redacted PDF document</li>
        </ol>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Ensuring Document Security and Compliance</h3>
        <p className="mb-4">
          In today's digital age, protecting sensitive information is more crucial than ever. Our AI-powered PDF redaction tool helps you maintain compliance with data protection regulations such as GDPR, CCPA, and HIPAA. By using our tool, you can confidently share documents without the risk of exposing confidential information.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Get Started with Intelligent PDF Redaction Today</h3>
        <p className="mb-4">
          Experience the power of AI-driven PDF redaction for yourself. Upload your document now and see how easy it is to secure your sensitive information. For enterprise solutions or to discuss custom redaction needs, contact our team at <a href="mailto:hello@ai-redact.com" className="text-blue-600 hover:underline">hello@ai-redact.com</a>.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h3>
        <div className="space-y-4">
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">Is my document secure during the redaction process?</summary>
            <p className="mt-2">Yes, we use state-of-the-art encryption and security measures to ensure your documents remain confidential throughout the redaction process. Our servers are regularly audited for security compliance.</p>
          </details>
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">Can I customize what information gets redacted?</summary>
            <p className="mt-2">Absolutely! While our AI can automatically detect common types of sensitive information, you have full control over what gets redacted. You can specify custom words, phrases, or patterns to be redacted.</p>
          </details>
          <details className="bg-white p-4 rounded-lg shadow">
            <summary className="font-semibold cursor-pointer">How accurate is the AI in detecting sensitive information?</summary>
            <p className="mt-2">Our AI model has been trained on a vast dataset and can accurately identify most types of sensitive information. However, we always recommend a human review of the suggested redactions before finalizing the document.</p>
          </details>
        </div>
      </section>
    </div>
  );
};

export default SEOComponent;